
.App h3 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.App {
  --f7-list-margin-vertical: 8px;
  text-align: center;
}

.navbar-bg {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.navbar a > * {
  vertical-align:  middle;
  display: inline-block;
  padding: 0.2rem;
  color: white;
}

.navbar i{
  color: lightgreen;
}

.space-right{
  margin-right: 15px;
}

.space-left{
  margin-left: 10px;
}

.App-header .float-right{
  position: fixed;
  right: 3em;
  top: 2em;
}

.list{
  padding: 10px;
}

.App .hidden{
  display: none;
}

.App .modal{
  z-index: 200;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0;
}

.modal .settings{
  width: 300px;
  margin: auto;
  position: relative;
  padding: 10px;
  background: white;
}

.button{
  width: auto;
}

.modal .close-button{
  padding: auto;
}

.action-button{
  margin-left: 15px;
}

.action-button:hover{
  cursor: pointer;
}

.asset-list, .events-list, .live-list{
  width: 100%;
}

.App .data-table td{
  vertical-align:  top;
  --f7-table-cell-padding-left: 15px;
}

.link{
  color: var(--f7-button-text-color, var(--f7-theme-color));
}
.link:hover{
  cursor: pointer;
}

.button.space-left.text-color-red{
  min-width: 100px;
}

@media (min-width: 768px) {
  .list{
    width: calc(100% - 10px);
  }
  .scroll-table{
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;  }
  .Multiview .scroll-table{
    height: calc(100% - var(--f7-navbar-height) - var(--f7-safe-area-top) -2em);
  }
  .scroll-table tr.fixed{
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
  }
  .scroll-table .fixed-bot{
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: white;
    width: 100%;
  }
}
@media (min-width: 1080px) {

}
